<i18n>
{
  "de": {
    "scenariosTitle": "Szenarien",
    "noScenarios": "Dieses Portfolio enthält keine Szenarien",
    "simulationRunning": "Simulation läuft",
    "copyButtonLabel": "Szenario duplizieren",
    "resetButtonLabel": "Szenarien zurücksetzen",
    "deleteConfirmation": "Wollen Sie Szenario \"{description}\" wirklich löschen?",
    "resetConfirmation": "Wollen Sie alle Szenarien löschen und die Standard-Szenarien wiederherstellen?",
    "pendingLabel": "Änderungen werden gespeichert und Portfolio neu simuliert..."
  }
}
</i18n>

<template>
  <div class="scenarios-section">
    <div v-if="error">{{ error }}</div>
    <div v-else-if="pending">{{ $t('pendingLabel') }}</div>
    <section v-else-if="portfolio" class="scenario-list">
      <ul>
        <li v-for="scenario in portfolio.scenarios" :key="scenario.id">
          <router-link :to="{ name: 'viewScenario', params: { portfolio_id: portfolio.id, scenario_id: scenario.id }}">
            <scenario-icon :color="scenario.color" />{{ scenario.description }}
          </router-link>
          <!-- <list-button-wrapper>
            <list-edit-button @click="onViewScenario(scenario)" />
            <list-button v-if="portfolio.permissions.EDIT_SCENARIOS" @click="copyScenario(scenario)" :img-src="'/icons/copy.svg'" :label="$t('copyButtonLabel')" />
            <list-delete-button v-if="portfolio.permissions.EDIT_SCENARIOS && hasMultipleScenarios" @click="deleteScenario(scenario)" />
          </list-button-wrapper> -->
        </li>
      </ul>
      <button-wrapper>
        <!-- <button class="button" v-if="portfolio.permissions.EDIT_SCENARIOS" type="submit" @click.prevent="resetScenarios()">{{ $t('resetButtonLabel') }}</button> -->
      </button-wrapper>
    </section>
  </div>
</template>

<script>
/* import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListButton from '@/components/shared/lists/ListButton'
import ListEditButton from '@/components/shared/lists/ListEditButton'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton' */

import { mapActions } from 'vuex'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import ScenarioIcon from '@/components/shared/ScenarioIcon'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      'pending': null,
      'error': null
    }
  },
  computed: {
    hasMultipleScenarios () {
      return this.portfolio.scenarios && this.portfolio.scenarios.length > 1
    }
  },
  methods: {
    ...mapActions({
      deleteScenarioAction: 'portfolio/deleteScenario',
      addScenarioAction: 'portfolio/addScenario',
      resetScenariosAction: 'portfolio/resetScenarios'
    }),
    onViewScenario (scenario) {
      this.$router.push({ name: 'viewScenario', params: { portfolio_id: this.portfolio.id, scenario_id: scenario.id } })
    },
    async copyScenario (scenario) {
      // eslint-disable-next-line
      const { definition, description: d, portfolio_id } = scenario
      const description = `${d} (Kopie)`
      this.pending = true
      try {
        await this.addScenarioAction({ definition, description, portfolio_id })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    },
    async deleteScenario (scenario) {
      const { description } = scenario
      const confirmationText = this.$t('deleteConfirmation', { description })
      if (confirm(confirmationText)) {
        this.pending = true
        try {
          await this.deleteScenarioAction(scenario)
        } catch (error) {
          this.error = error
        }
        this.pending = false
      }
    },
    async resetScenarios () {
      const confirmationText = this.$t('resetConfirmation')
      if (confirm(confirmationText)) {
        this.pending = true
        try {
          await this.resetScenariosAction(this.portfolio.id)
        } catch (error) {
          this.error = error
        }
        this.pending = false
      }
    }
  },
  components: {
    /* ListButton,
    ListButtonWrapper,
    ListEditButton,
    ListDeleteButton, */
    ButtonWrapper,
    ScenarioIcon
  }
}
</script>

<style>
.scenarios-section .scenario-list {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  justify-self: start;
  gap: var(--spacing-m);
  font-size: var(--font-s);
}

.scenarios-section .scenario-list > ul,
.scenarios-section .scenario-list > ul > li {
  display: contents;
}

.scenarios-section .scenario-list > h3,
.scenarios-section .scenario-list > ul > li :first-child {
  grid-column-start: 1;
  margin: 0;
}

</style>
