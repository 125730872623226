<i18n>
{
  "de": {
    "pageTitle": "Einstellungen",
    "energyCarrierSettings": "Energieträger",
    "energyCarrierSettingsInfo": "Emissionsfaktoren und Energiefaktoren für Energieträger",
    "heatingTypesSettings": "Heizungstypen",
    "heatingTypesSettingsInfo": "Nutzungsgrade von Heizkesseln und Gütegrade von Wärmepumpen",
    "investmentCostsSettings": "Kostenkennzahlen",
    "investmentCostsSettingsInfo": "Flächen- und leistungsbasierte Kostenkennzahlen von Komponenten der Gebäudehülle und -technik",
    "lifespansSettings": "Lebensdauer",
    "lifespansSettingsInfo": "Standardlebensdauer von Komponenten der Gebäudehülle und -technik",
    "targetsSettings": "Reduktionsziele",
    "targetsSettingsInfo": "Ziele für die Reduktion von GHG-Emissionen und Energiebedarf",
    "heatGridsSettings": "Wärmenetze",
    "heatGridsSettingsInfo": "Erstellen und bearbeiten von Wärmenetzen",
    "tagGroupsSettings": "Kategorisierungen",
    "tagGroupsSettingsInfo": "Kategorisierung von Liegenschaften"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady" class="portfolio-settings-page">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <div>
        <router-link class="settings-link" :to="{ name: 'settingsTargets', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('targetsSettings') }}</strong></div>
          <div>{{ $t('targetsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsEnergyCarriers', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('energyCarrierSettings') }}</strong></div>
          <div>{{ $t('energyCarrierSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsHeatingTypes', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('heatingTypesSettings') }}</strong></div>
          <div>{{ $t('heatingTypesSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsInvestmentCosts', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('investmentCostsSettings') }}</strong></div>
          <div>{{ $t('investmentCostsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsLifespans', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('lifespansSettings') }}</strong></div>
          <div>{{ $t('lifespansSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'heatGrids', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('heatGridsSettings') }}</strong></div>
          <div>{{ $t('heatGridsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsTagGroups', params: { portfolio_id: portfolio.id }}">
          <div><strong>{{ $t('tagGroupsSettings') }}</strong></div>
          <div>{{ $t('tagGroupsSettingsInfo') }}</div>
        </router-link>
      </div>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    MainLayout
  }
}
</script>

<style>
.portfolio-settings-page .portfolio-name .button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: baseline;
  gap: var(--spacing-m);
}

.portfolio-settings-page .settings-link {
  display: block;
  max-width: 500px;
  margin-top: var(--spacing-s);
  padding: var(--spacing-m);
  background-color: var(--background-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
</style>
