<i18n>
{
  "de": {
    "pageTitle": "Szenarien"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('pageTitle') }}</h1>
      <scenarios-section :portfolio="portfolio" />
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ScenariosSection from '@/components/scenarios/ScenariosSection'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  components: {
    MainLayout,
    ScenariosSection
  }
}
</script>
